<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { mdiChevronDown } from "@mdi/js";

withDefaults(
  defineProps<{
    bgColor?: string;
    btnColor?: string;
    showChevron?: boolean;
    btnClasses?: string[] | null;
    btnUnderline?: boolean;
  }>(),
  {
    bgColor: "#FFF",
    showChevron: true,
  },
);

const { locale: currentLocale, locales, t } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== currentLocale.value);
});
</script>

<template>
  <div class=" w-100">
    <v-menu class="language-switcher-component">
      <template #activator="{ props, isActive }">
        <HwButton
          v-bind="props"
          text
          :background="btnColor"
          class="w-100"
          :class="btnClasses"
          :underline="btnUnderline"
        >
          <!--        <HwButton v-bind="props" text background="#ebebeb">-->
          <template #icon v-if="showChevron">
            <v-icon
              class="hw-btn-base-icon"
              :icon="[mdiChevronDown]"
              :style="{ transform: isActive ? 'rotate(180deg)' : '' }"
            />
          </template>

          <span class="mr-2"> {{ t("language") }}: </span>

          {{ currentLocale }}
        </HwButton>
      </template>

      <v-list :bgColor="bgColor" width="230" class="mx-auto">
        <v-list-item
          v-for="locale in availableLocales"
          :key="locale.code"
          :to="switchLocalePath(locale.code)"
          :title.attr="locale.code"
        >
          <v-list-item-title class="text-uppercase v-btn--size-default">
            {{ locale.code }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<i18n>
{
  "de": {
    "language": "Sprache"
  },
  "en": {
    "language": "Language"
  }
}
</i18n>
